import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import Seo from '../components/shared/Seo'
import Layout from '../components/layout'
import Icons from '../components/shared/Icons'
const YTScreenshot = styled.div`
  display: block;
  text-decoration: none;
  width: 100%;
  padding-bottom: 55%;
  background: url(${({ videoID }) =>
    'https://i.ytimg.com/vi/' + videoID + '/mqdefault.jpg'});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0;

  & + .title {
    text-decoration: none;
    color: var(--dark);
  }
`
class ResourcesPage extends React.Component {
  render() {
    const {
      section1Title,
      section2Title,
      section3Title,
      section4Title,
      youtubeVideosSectionTitle,
      section1Resources,
      section2Resources,
      section4Resources,
      youtubeVideos,
    } = this.props.data.page
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <Container className="py-5">
          <div
            className="py-3 px-5 mb-4 "
            style={{ background: 'rgba(253,215,201,0.4)' }}
          >
            <h2 className="text-primary mb-4">{section1Title}</h2>
            {section1Resources.map((res, i) => (
              <p key={i}>
                {res.title} -{' '}
                <a
                  title={res.title}
                  href={`${res.file.url}?popupDownload=true`}
                  target="_blank"
                >
                  Download
                </a>
              </p>
            ))}
          </div>
          <div
            className="py-3 px-5 mb-4 "
            style={{ background: 'rgba(253,215,201,0.4)' }}
          >
            <h2 className="text-primary mb-4">{section2Title}</h2>
            {section2Resources.map((res, i) => (
              <p key={i}>
                {res.title} -{' '}
                <a title={res.title} href={res.file.url} download>
                  Download
                </a>
              </p>
            ))}
          </div>
          <div
            className="py-3 px-5 mb-4 "
            style={{ background: 'rgba(253,215,201,0.4)' }}
          >
            <h2 className="text-primary mb-4">{section4Title}</h2>
            {section4Resources.map((res, i) => (
              <p key={i}>
                {res.title} -{' '}
                <a title={res.title} href={res.file.url} download>
                  Download
                </a>
              </p>
            ))}
          </div>
          {youtubeVideosSectionTitle !== ' ' && (
            <div className="my-5">
              <div className="   style={{ background: 'rgba(253,215,201,0.4)' }}px-5 py-5">
                <h2 className="text-primary mb-4">
                  {youtubeVideosSectionTitle}
                </h2>
                <div className="row">
                  {youtubeVideos.map((vid, index) => {
                    return (
                      <div
                        className="col-sm-6 col-lg-4 col-md-6 mb-3"
                        key={index}
                      >
                        {' '}
                        <div>
                          <a
                            href={`https://www.youtube.com/watch?v=${
                              vid.description
                            }`}
                            target="_blank"
                          >
                            <YTScreenshot videoID={vid.description} />
                            <h5 className="title mt-2">{vid.title}</h5>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </Container>

        <Icons />
      </Layout>
    )
  }
}

export default ResourcesPage
export const query = graphql`
  {
    seo: contentfulResourcesPage {
      title: pageTitle
      description
      keywords
    }
    page: contentfulResourcesPage {
      section1Title
      section2Title
      section3Title
      section4Title

      youtubeVideosSectionTitle
      section1Resources {
        title
        description
        file {
          url
        }
      }
      section2Resources {
        title
        description
        file {
          url
        }
      }
      section4Resources {
        title
        description
        file {
          url
        }
      }
      youtubeVideos {
        title
        description
      }
    }
  }
`
